import 'utils/dayjs';
import { createRoutesFromElements, Route, Routes } from 'react-router-dom';
import { ErrorStackContext, PageContext } from 'app/context';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React, { lazy, Suspense, useEffect, useMemo, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Container from 'react-bootstrap/Container';
import fetchJSON from '@nguyengg/lambdajs/fetch-json';
import { List } from 'immutable';
import Navbar from 'components/navbar';
import Spinner from 'react-bootstrap/Spinner';
import { SWRConfig } from 'swr';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
const BrowseGalleries = lazy(() => import(
/* webpackPrefetch: true */
/* webpackChunkName: "browse-galleries" */
'./galleries/browse'));
const BrowseVideos = lazy(() => import(
/* webpackPrefetch: true */
/* webpackChunkName: "browse-videos" */
'./videos/browse'));
const BrowseVideosTags = lazy(() => import(
/* webpackPrefetch: true */
/* webpackChunkName: "browse-videos-tags" */
'./videos/browse-tags'));
/*
const UploadGalleries = lazy(
    () =>
        import(
            /!* webpackPrefetch: true *!/
            /!* webpackChunkName: "upload-galleries" *!/
            './galleries/upload'
        )
)

const UploadVideos = lazy(
    () =>
        import(
            /!* webpackPrefetch: true *!/
            /!* webpackChunkName: "upload-videos" *!/
            './videos/upload'
        )
)
*/
const ViewGalleries = lazy(() => import(
/* webpackPrefetch: true */
/* webpackChunkName: "galleries" */
'./galleries/view'));
const ViewVideos = lazy(() => import(
/* webpackPrefetch: true */
/* webpackChunkName: "videos" */
'./videos/view'));
function Home() {
    useEffect(() => {
        document.title = 'Welcome to the World of Winery!';
    }, []);
    return (React.createElement(Container, { fluid: true },
        React.createElement("h1", null, "Under construction"),
        React.createElement("main", null,
            "This will be a social media website that is",
            ' ',
            React.createElement("a", { href: "https://digg.com/", rel: "external nofollow noopener noreferrer", target: "_blank" }, "https://digg.com/"),
            ' ',
            "meets",
            ' ',
            React.createElement("a", { href: "https://www.reddit.com/", rel: "external nofollow noopener noreferrer", target: "_blank" }, "https://www.reddit.com/"),
            ' ',
            "with sprinkles of",
            ' ',
            React.createElement("a", { href: "https://www.stumbleupon.com/", rel: "external nofollow noopener noreferrer", target: "_blank" }, "https://www.stumbleupon.com/"),
            ' ',
            "on top, but dedicated for wine enthusiasts all over the world!")));
}
function Fallback() {
    return (React.createElement(Container, { fluid: true },
        "Loading... ",
        React.createElement(Spinner, { animation: "border" })));
}
function NotFound() {
    return (React.createElement(Container, { fluid: true },
        React.createElement(Alert, { variant: "danger" }, "Page Not Found")));
}
const routes = [
    React.createElement(Route, { key: "home", index: true, path: "/", element: React.createElement(Home, null) }),
    React.createElement(Route, { key: "browseGalleries", path: "/galleries/", element: React.createElement(Suspense, { fallback: React.createElement(Fallback, null) },
            React.createElement(BrowseGalleries, null)) }),
    /*
    <Route
        key="uploadGalleries"
        path="/galleries/upload"
        element={
            <Suspense fallback={<Fallback />}>
                <UploadGalleries />
            </Suspense>
        }
    />,
  */
    React.createElement(Route, { key: "viewGalleries", path: "/galleries/:id", element: React.createElement(Suspense, { fallback: React.createElement(Fallback, null) },
            React.createElement(ViewGalleries, null)) }),
    React.createElement(Route, { key: "browseVideos", path: "/videos/", element: React.createElement(Suspense, { fallback: React.createElement(Fallback, null) },
            React.createElement(BrowseVideos, null)) }),
    React.createElement(Route, { key: "browseVideosTags", path: "/videos/tags", element: React.createElement(Suspense, { fallback: React.createElement(Fallback, null) },
            React.createElement(BrowseVideosTags, null)) }),
    /*
    <Route
        key="uploadVideos"
        path="/videos/upload"
        element={
            <Suspense fallback={<Fallback />}>
                <UploadVideos />
            </Suspense>
        }
    />,
  */
    React.createElement(Route, { key: "viewVideos", path: "/videos/:id/:filename?", element: React.createElement(Suspense, { fallback: React.createElement(Fallback, null) },
            React.createElement(ViewVideos, null)) }),
    React.createElement(Route, { key: "404", path: "*", element: React.createElement(NotFound, null) }),
];
export default function Layout({ context }) {
    const [errors, setErrors] = useState(List());
    const toasts = useMemo(() => (React.createElement(ToastContainer, { position: "bottom-center" }, errors.toArray().map(({ title, error, autoHide }, i) => (React.createElement(Toast, { key: i, autohide: autoHide !== undefined, bg: "danger", delay: autoHide, onClose: () => setErrors((errors) => errors.delete(i)) },
        React.createElement(Toast.Header, { closeButton: true },
            React.createElement("strong", { className: "me-auto" }, "Error"),
            title && React.createElement("small", null, title)),
        React.createElement(Toast.Body, null, error.toString())))))), [errors]);
    const [queryClient] = useState(() => new QueryClient({
        defaultOptions: { queries: { queryFn: ({ queryKey }) => fetchJSON(queryKey[0]) } },
    }));
    function pushError(error) {
        setErrors((errors) => errors.push(error));
    }
    return (React.createElement(PageContext.Provider, { value: context },
        React.createElement(ErrorStackContext.Provider, { value: { errors, pushError } },
            React.createElement(QueryClientProvider, { client: queryClient },
                React.createElement(SWRConfig, { value: {
                        fallback: context.fallback,
                        keepPreviousData: true,
                        onError(error, title) {
                            pushError({ title, error, autoHide: 5000 });
                        },
                    } },
                    React.createElement(Navbar, null),
                    React.createElement(Routes, null, routes),
                    toasts)))));
}
export const appRoutes = routes.map((r) => createRoutesFromElements(r)).reduce((a, b) => a.concat(b));
